import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const FaqAccordion: ComponentStyleConfig = {
	baseStyle: {
		contentWrapper: {
			borderColor: "brand.light",
			borderStyle: "solid",
			borderBottomWidth: "2px",

			paddingY: {
				base: 4,
				md: 10,
			},

			"&.expanded": {
				borderBottomWidth: "2px",
				borderColor: "brand.dark",
			},
		},
		label: {
			...figmaHelper({ base: 18, md: 24 }, { base: 26, md: 32 }),
			fontWeight: "semibold",
			textAlign: "left",
			paddingRight: {
				base: 5,
				md: 2,
			},
		},
		panel: {
			...figmaHelper({ base: 14, md: 16 }, { base: 23, md: 24 }),
			paddingTop: {
				base: 6,
				md: 9,
			},
			paddingBottom: 0,
			paddingLeft: 0,

			"* + *": {
				marginTop: 4,
			},
		},
		button: {
			paddingLeft: 0,
			paddingRight: { base: 0, md: 4 },
			justifyContent: "space-between",
			alignItems: "start",
			fontFamily: "sans",
			flexDirection: {
				base: "column-reverse",
				md: "row",
			},
			gap: {
				base: 2,
				md: 0,
			},

			"&:hover": {
				color: "brand.highlight.hover",
				backgroundColor: "unset",
			},
		},
		toggleIcon: {
			color: "brand.highlight",
			alignSelf: "flex-end",
		},
	},
};

export default FaqAccordion;
