import { figmaHelper } from "src/styles/typography";
import type { ComponentMultiStyleConfig } from "@chakra-ui/theme";

const PolicyCard: ComponentMultiStyleConfig = {
	parts: [
		"wrapper",
		"name",
		"nameSkeleton",
		"price",
		"secondaryActions",
		"modalButtonWrapper",
		"durationElements",
		"durationLabel",
	],
	baseStyle: ({ isDisabled }) => ({
		wrapper: {
			bg: "white",
			color: "brand.dark",
			borderRadius: "md",
			padding: 7,
			position: "relative",
			width: "100%",
			maxWidth: "sm",
		},
		name: {
			hyphens: "auto",
			overflowWrap: "anywhere",
			paddingBottom: 7,
		},
		nameSkeleton: {
			display: "inline-block",
			width: "full",
		},
		price: {
			...figmaHelper(16, 26),
			color: "brand.medium",
			paddingY: 0,
			paddingTop: 5,
		},
		secondaryActions: {
			display: "flex",
			flexDir: "column",
			alignItems: "flex-start",

			gap: 2,
			paddingTop: 7,
			paddingBottom: 7,

			color: "brand.darker",

			// This is only when the tooltip is displayed and uses shouldWrapChildren
			"> span": {
				display: "flex",
				flexDir: "column",
				alignItems: "flex-start",
				gap: 4,
			},
		},
		modalButtonWrapper: {
			svg: {
				color: "brand.highlight",
				transitionProperty: "common",
				transitionDuration: "300ms",
			},

			_hover: {
				svg: {
					transform: "translateX(.5rem)",
				},
			},
		},
		durationElements: {
			display: "flex",
			flexDirection: "column",
			color: isDisabled ? "brand.medium" : undefined,

			">*:not(:last-child)": {
				paddingBottom: 2,
			},
		},
		durationLabel: {
			fontWeight: "bold",
		},
	}),
};

export default PolicyCard;
