import type { ComponentStyleConfig } from "@chakra-ui/theme";

const DatePicker: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			// Need to override the react day picker css variables being set at the :root level
			".rdp": {
				"--rdp-accent-color": "var(--chakra-colors-brand-highlight)",
				"--rdp-background-color":
					"var(--chakra-colors-brand-highlight-disabled)",
			},
		},
	},
};

export default DatePicker;
