import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const FaqSection: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			bg: "brand.lighter",
			paddingTop: {
				base: 8,
				md: 20,
			},
			paddingBottom: {
				base: 12,
				md: 24,
			},
		},
		titleContainer: {
			paddingBottom: {
				base: 0,
				md: 2,
				lg: 6,
			},
		},
		title: {
			textAlign: "center",
			fontFamily: "sans",
			color: "brand.dark",
			...figmaHelper({ base: 30, lg: 42 }, { base: 31, lg: 52 }),
		},
		subtitle: {
			paddingTop: 8,
			fontWeight: "medium",
			textAlign: "center",
			color: "brand.dark",
		},
	},
};

export default FaqSection;
